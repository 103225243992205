import React from 'react';
import { compose, withHandlers, withState, branch, renderNothing } from 'recompose';
import classnames from 'classnames';
import { Image } from '../images/image';
import { DEVICE } from '../../helpers/user-agent';

import styles from '../../styles/components/smartbanner.module.scss';

export const SmartBannerComponent = React.memo(({ onClose }) => {
  const {
    SMART_BANNER: { title, button, iconGoogle, iconApple, buttonUrlGoogle, buttonUrlApple, author }
  } = window.CONFIG;

  const type = DEVICE.IOS() ? 'ios' : 'android';

  const data = {
    ios: {
      icon: iconApple,
      url: buttonUrlApple
    },
    android: {
      icon: iconGoogle,
      url: buttonUrlGoogle
    }
  };

  return (
    <div className={classnames(styles.container, styles[type])}>
      <div onClick={onClose} className={styles.exit} />
      <Image src={data[type].icon} className={styles.icon} />
      <div className={styles.info}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.author}>{author}</div>
        </div>
      </div>
      <a href={data[type].url} target='_blank' className={styles.button} rel='noreferrer'>
        <span className={styles.label}>{button}</span>
      </a>
    </div>
  );
});

export const SmartBanner = compose(
  withState('isClosed', 'setIsClosed', localStorage.getItem('isBannerClosed')),
  withHandlers({
    onClose:
      ({ setIsClosed }) =>
      () => {
        setIsClosed(true);
        localStorage.setItem('isBannerClosed', 'true');
      }
  }),
  branch(({ isClosed }) => !DEVICE.IS_MOBILE_BROWSER() || !window.CONFIG.SMART_BANNER.show || isClosed, renderNothing)
)(SmartBannerComponent);
