import React, { useMemo } from 'react';
import uuid from 'uuid';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { Collapse, CollapsePanel } from '../../../../collapse';
import { Grid, Column } from '../../../grid';
import { isVisible } from '../../../with-layout';
import { CollapseItem } from './collapse-item';

export const Component = React.memo(({ expanded, items, toggle }) => {
  const collapseId = useMemo(() => uuid('collapse-panel'), []);

  return (
    <Collapse expanded={expanded} className={styles['collapsed-navigation']}>
      <span id={collapseId} tabIndex={-1} className={styles['collapsing-label']}>
        Collapsing Menu
      </span>
      <CollapsePanel tabIndex={0} aria-labelledby={collapseId} aria-expanded={expanded}>
        <Grid renderAs='nav'>
          <Column>
            <ul className={styles['collapsed-items']}>
              {items.map(({ labelText, link, nativeTrigger, isExternal, openNewTab, displayCondition }) => (
                <CollapseItem
                  key={labelText}
                  toggle={toggle}
                  labelText={labelText}
                  link={link}
                  nativeTrigger={nativeTrigger}
                  isExternal={isExternal}
                  openNewTab={openNewTab}
                  displayCondition={displayCondition}
                />
              ))}
            </ul>
          </Column>
        </Grid>
      </CollapsePanel>
    </Collapse>
  );
});

export const CollapsedNav = isVisible({ name: 'desktopHeaderCollapseNav' })(Component);
