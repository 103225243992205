import { compose, lifecycle, withHandlers } from 'recompose';
import { UserService } from '../../services/user-service';
import { ConfigService } from '../../services/config-service';

// One year in milliseconds
const DEFAULT_SESSION_TIME = 365 * 24 * 60 * 60 * 1000;

export const withAutoLogoutHandlers = withHandlers({
  initializeSessionTimer: () => () => {
    if (UserService.hasUser()) {
      clearTimeout(UserService.getLogoutTimerId());

      const sessionTime = ConfigService.get('ACCOUNT.CONFIGURATION.sessionTime');

      const setTimeoutId = setTimeout(() => {
        UserService.logout();
      }, sessionTime || DEFAULT_SESSION_TIME);

      UserService.setLogoutTimerId(setTimeoutId);
    }
  }
});

export const withAutoLogoutListeners = compose(
  withAutoLogoutHandlers,
  lifecycle({
    componentDidMount() {
      const { initializeSessionTimer } = this.props;

      document.addEventListener('click', initializeSessionTimer);
      document.addEventListener('touchstart', initializeSessionTimer);
    },
    componentWillUnmount() {
      const { initializeSessionTimer } = this.props;

      document.removeEventListener('click', initializeSessionTimer);
      document.removeEventListener('touchstart', initializeSessionTimer);

      clearTimeout(UserService.getLogoutTimerId());
    }
  })
);
