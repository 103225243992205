import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../../components/link';
import styles from '../../../styles/widgets/feature-fit-v2/ff-kpi-list.module.scss';

const UpdateBlock = ({ updateLabel, formattedDate, updateAction, updateLinkTitle, isUpdateLabelOff = false }) => {
  return (
    <div className={styles['update-container']}>
      <p className={classnames(styles['update-label'], { 'update-label-off': isUpdateLabelOff })}>
        {updateLabel}&nbsp;<b>{formattedDate}</b>
      </p>
      {updateAction ? (
        <Link className={styles['update-link']} onClick={updateAction} isButton ariaLabel='update answers'>
          {updateLinkTitle}
        </Link>
      ) : null}
    </div>
  );
};

UpdateBlock.propTypes = {
  formattedDate: PropTypes.string.isRequired,
  updateLabel: PropTypes.string.isRequired,
  updateAction: PropTypes.func,
  updateLinkTitle: PropTypes.string,
  isUpdateLabelOff: PropTypes.bool
};

export default UpdateBlock;
