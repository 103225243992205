import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const { userAttributeType, userAttributeField, identifier } = ownProps;
  const { userAttributes } = state;

  const reusableType = userAttributeType?.split('.')[0];
  const name = userAttributeType?.split('.')[1];

  if (name && identifier) {
    const attributeType = reusableType;

    return {
      existingValue: state.userAttributes?.[attributeType]?.[name]?.[identifier]?.[userAttributeField],
      previousState: state.userAttributes?.[attributeType]?.[name] ?? {},
      name,
      userAttributes,
      attributeType
    };
  }

  return {
    userAttributes,
    existingValue: state.userAttributes?.[userAttributeType]?.[userAttributeField],
    attributeType: userAttributeType
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { existingValue, userAttributes, attributeType } = stateProps;

  return { ...dispatchProps, ...ownProps, attributeType, existingValue, userAttributes };
};

export const withQuestionState = connect(mapStateToProps, null, mergeProps);
