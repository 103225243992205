import { LocalStorageService } from '@packages/helpers/core/services/local-storage';

export class AccordionsService {
  static store = new LocalStorageService('accordions');

  static init(name) {
    return AccordionsService.store.set(store => ({
      ...store,
      [name]: {}
    }));
  }

  static get(name, key) {
    if (!AccordionsService.has(name)) {
      return AccordionsService.init(name);
    }

    return AccordionsService.store.get()[name][key];
  }

  static set(name, key, value) {
    return AccordionsService.store.set(store => ({
      ...store,
      [name]: {
        ...store[name],
        [key]: value
      }
    }));
  }

  static toggle(name, key) {
    return AccordionsService.store.set(store => ({
      ...store,
      [name]: {
        ...store[name],
        [key]: !store[name][key]
      }
    }));
  }

  static has(name) {
    return name in AccordionsService.store.get();
  }

  static getAll(name) {
    return AccordionsService.store.get()[name];
  }
}
