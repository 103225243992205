import React from 'react';
import classnames from 'classnames';
import styles from '../../../../styles/components/page-header/header-content.module.scss';
import { Column, Grid } from '../../grid';
import { Body } from '../components';

export const HeaderContent = ({ className, contentAlign = 'left', children }) => (
  <div className={classnames(styles.content, className, styles[contentAlign])}>
    <Grid as={Body} className={styles.body}>
      <Column className={styles.column}>{children}</Column>
    </Grid>
  </div>
);
