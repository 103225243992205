import Loadable from 'react-loadable';
import { BARRIER, CHECKPOINT } from '../helpers/navigation';
import { Loader } from '../components/with-loader';
import { asTemplate } from '../components/layout/with-layout';
import { routes as signUpFlow } from './account/sign-up';
import { routes as signInFlow } from './account/sign-in';
import { routes as resetPasswordFlow } from './account/reset-password';
import { routes as mainFlow } from './account/main';

const LogoutCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "logout-complete-page"*/ '../pages/account/components/complete-pages').then(
      module => module.LogoutCompletePage
    ),
  loading: Loader
});

const BarrierPage = Loadable({
  loader: () => import(/* webpackChunkName: "barrier"*/ '../pages/barrier').then(module => module.BarrierPage),
  loading: Loader
});

export const accountsRoutes = [
  ...signUpFlow,
  ...signInFlow,
  ...resetPasswordFlow,
  ...mainFlow,
  {
    to: '/account/logout',
    component: asTemplate(CHECKPOINT)(LogoutCompletePage)
  },
  {
    to: '/barrier',
    component: asTemplate(BARRIER)(BarrierPage)
  }
];
