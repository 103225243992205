import React from 'react';
import uuid from 'uuid';
import styles from '../../../../../../styles/components/layout-popup/unmanaged.module.scss';
import { CoachMessage } from '../../../../../coach-message';
import IntersectionVisible from '../../../../../intersection-visibility';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../../../buttons/button-with-icon';
import { InputGroup } from '../../../../../inputs/input-group';
import { TBody } from '../../../../../template';
import { PurifiedHtmlContainer } from '../../../../purified-html-container';
import { Spacer } from '../../../../spacers';

export const UnmanagedState = React.memo(({ onAccept, onManage, onInputAppear }) => {
  const {
    ACCOUNT: {
      CONSENT_PAGE: {
        unmanaged: {
          body,
          message,
          buttons: { confirm, manage }
        }
      }
    }
  } = window.CONFIG;

  return (
    <>
      {message && (
        <CoachMessage>
          {[].concat(message).map(line => (
            <PurifiedHtmlContainer
              key={uuid('consent-unmanaged-message')}
              renderAs='p'
              className='typography'
              html={line}
            />
          ))}
        </CoachMessage>
      )}
      {body && <TBody html={body} />}
      <Spacer renderAs='div' type='component' />
      <IntersectionVisible onShow={onInputAppear} once>
        <InputGroup type='coach'>
          <ButtonWithIcon className={styles.button} onClick={onAccept}>
            {confirm}
          </ButtonWithIcon>
          <ButtonWithIcon className={styles.button} onClick={onManage}>
            {manage}
          </ButtonWithIcon>
        </InputGroup>
      </IntersectionVisible>
    </>
  );
});
