import React from 'react';
import classnames from 'classnames';
import styles from '../styles/components/coach-message.module.scss';
import { ConfigService } from '../services/config-service';
import { PurifiedHtmlContainer } from './layout/purified-html-container';
import { Image } from './images/image';
import { useSubscribe } from './slots';
import { withRequiredIndicator } from './inputs/required-indicator';

export const CoachAvatar = React.memo(({ className }) => {
  const { avatarUrl, avatarAlt } = ConfigService.get('COACH');

  if (!avatarUrl) return null;

  return <Image className={classnames(styles.image, className)} src={avatarUrl} title={avatarAlt} />;
});

export const BubbleComponent = React.memo(({ children, html, className }) => {
  const bubblePerItem = ConfigService.get('COACH.bubblePerItem', false);

  return (
    <PurifiedHtmlContainer
      parse={false}
      html={html}
      className={classnames(styles.bubbles, bubblePerItem ? styles.plural : styles.singular, className)}
    >
      {children}
    </PurifiedHtmlContainer>
  );
});

export const CoachBubbles = withRequiredIndicator(BubbleComponent);

const CoachBubblesSlot = React.memo(({ type, name }) => {
  const bubblePerItem = ConfigService.get('COACH.bubblePerItem', false);

  const slot = useSubscribe(`${type}-${name}-coach-message`);

  return <div ref={slot} className={classnames(styles.bubbles, bubblePerItem ? styles.plural : styles.singular)} />;
});

export const CoachMessage = React.memo(({ prependSlot, appendSlot, className, ...props }) => (
  <div className={classnames(styles.container, className)}>
    <div className={styles.header}>
      <div className={styles.avatar}>
        <CoachAvatar />
      </div>
    </div>
    <div className={styles.body}>
      {prependSlot && <CoachBubblesSlot type='prepend' name={prependSlot} />}
      <CoachBubbles {...props} />
      {appendSlot && <CoachBubblesSlot type='append' name={appendSlot} />}
    </div>
  </div>
));
