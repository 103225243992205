import React from 'react';
import { useSelector } from 'react-redux';
import styles from '@apps/firsthomecoach/src/styles/components/application.module.scss';
import { ApplicationFooter } from '@apps/firsthomecoach/src/components/layout/application-footer';
import { ApplicationHeader } from '@apps/firsthomecoach/src/components/layout/application-header';
import { Sidebar } from '@apps/firsthomecoach/src/components/layout/sidebar';

export const Application = ({ children }) => {
  const offset = useSelector(state => state.layout.sizes.sidebar);

  return (
    <>
      <ApplicationHeader className={styles.header} />
      <main className={styles.content} style={{ paddingLeft: offset }}>
        <Sidebar className={styles.sidebar} />
        {children}
      </main>
      <ApplicationFooter className={styles.footer} />
    </>
  );
};
