import Loadable from 'react-loadable';
import { asTemplate } from '../../components/layout/with-layout';
import { Loader } from '../../components/with-loader';
import { ACCOUNT_HOME, ACCOUNT_HOME_OPTION, CHECKPOINT } from '../../helpers/navigation';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account"*/ '../../pages/account/main').then(module => module.Account),
  loading: Loader
});

const AccountDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-details"*/ '../../pages/account/main/details').then(
      module => module.AccountDetails
    ),
  loading: Loader
});

const AccountDetailsCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-details-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.AccountDetailsCompletePage
    ),
  loading: Loader
});

const AccountMarketing = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-details"*/ '../../pages/account/main/marketing').then(
      module => module.AccountMarketing
    ),
  loading: Loader
});

const AccountCookies = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-cookies"*/ '../../pages/account/main/cookies').then(
      module => module.AccountCookies
    ),
  loading: Loader
});

const DeleteAccount = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-delete-account"*/ '../../pages/account/main/delete-account').then(
      module => module.DeleteAccount
    ),
  loading: Loader
});

const DeleteAccountCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "delete-account-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.DeleteAccountCompletePage
    ),
  loading: Loader
});

const RequestData = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-request-data"*/ '../../pages/account/main/request-data').then(
      module => module.RequestData
    ),
  loading: Loader
});

export const routes = [
  {
    to: '/account',
    component: asTemplate(ACCOUNT_HOME)(Account)
  },
  {
    to: '/account/details',
    component: asTemplate(ACCOUNT_HOME_OPTION)(AccountDetails)
  },
  {
    to: '/account/details/complete',
    component: asTemplate(CHECKPOINT)(AccountDetailsCompletePage)
  },
  {
    to: '/account/marketing',
    component: asTemplate(ACCOUNT_HOME_OPTION)(AccountMarketing)
  },
  {
    to: '/account/user-consents',
    component: asTemplate(ACCOUNT_HOME_OPTION)(AccountCookies)
  },
  {
    to: '/account/delete-account',
    component: asTemplate(ACCOUNT_HOME_OPTION)(DeleteAccount)
  },
  {
    to: '/account/delete-account/complete',
    component: asTemplate(CHECKPOINT)(DeleteAccountCompletePage)
  },
  {
    to: '/account/request-data',
    component: asTemplate(ACCOUNT_HOME_OPTION)(RequestData)
  }
];
