import Loadable from 'react-loadable';
import { asTemplate } from '../../components/layout/with-layout';
import { Loader } from '../../components/with-loader';
import { ACCOUNT, CHECKPOINT } from '../../helpers/navigation';

const SignUpEmail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-up-email"*/ '../../pages/account/sign-up/email').then(
      module => module.SignUpEmail
    ),
  loading: Loader
});

const SignUpPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-up-password"*/ '../../pages/account/sign-up/password').then(
      module => module.SignUpPassword
    ),
  loading: Loader
});

const SignUpMagicCode = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-up-magic-code"*/ '../../pages/account/sign-up/magic-code').then(
      module => module.SignUpMagicCode
    ),
  loading: Loader
});

const SignUpMarketing = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-up-marketing"*/ '../../pages/account/sign-up/marketing').then(
      module => module.SignUpMarketing
    ),
  loading: Loader
});

const SignUpCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-up-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.SignUpCompletePage
    ),
  loading: Loader
});

export const routes = [
  {
    to: '/account/sign-up',
    component: asTemplate(ACCOUNT)(SignUpEmail)
  },
  {
    to: '/account/sign-up/password',
    component: asTemplate(ACCOUNT)(SignUpPassword)
  },
  {
    to: '/account/sign-up/magic-code',
    component: asTemplate(ACCOUNT)(SignUpMagicCode)
  },
  {
    to: '/account/sign-up/marketing',
    component: asTemplate(ACCOUNT)(SignUpMarketing)
  },
  {
    to: '/account/sign-up/account-ready',
    component: asTemplate(CHECKPOINT)(SignUpCompletePage)
  }
];
