import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { createReducer } from '../../utils';
import { UserService } from '../../../services/user-service';
import {
  LOG_OUT_SUCCESS,
  DELETE_ACCOUNT_SUCCESS,
  CREATE_SHADOW_USER_SUCCESS,
  GET_USER_SUCCESS,
  LOGIN_SUCCESS,
  CONFIRM_SIGN_UP_SUCCESS
} from '../account/action-types';
import { HOME_OR_RENTED_OUT } from '../../../helpers/constants';
import { KEY_FACTOR_TYPES } from '../../../helpers/property-goal/constants';
import { mapFees } from '../../../helpers/user-attributes/map-user-attributes';
import {
  GET_USER_ATTRIBUTES_SUCCESS,
  GET_USER_ATTRIBUTES_FAILURE,
  GET_SPECIFIED_USER_ATTRIBUTE_REQUEST,
  GET_SPECIFIED_USER_ATTRIBUTE_SUCCESS,
  GET_SPECIFIED_USER_ATTRIBUTE_FAILURE,
  SEND_USER_ATTRIBUTE_SUCCESS,
  SEND_USER_ATTRIBUTE_FAILURE,
  SEND_ADDITIONAL_USER_ATTRIBUTES_SUCCESS,
  SEND_ADDITIONAL_USER_ATTRIBUTES_FAILURE,
  DISPATCH_AFTER_USER_ATTRIBUTES,
  SEND_USER_ATTRIBUTE_REQUEST,
  SEND_GROUP_USER_ATTRIBUTE_SUCCESS,
  SEND_GROUP_USER_ATTRIBUTE_FAILURE
} from './actions';

const mapAttributesAfterInitiate = ({ goal, ...state }) => {
  return {
    ...state,
    goal: mapFees(goal)
  };
};

const mapAttributesValues = ({ payload, state = {} }) => {
  const configIsNotYetReady = payload === false;

  const defaultValues = {
    goal: {
      preferredDepositPercent: 0,
      depositPercent: 0,
      misc: 0,
      propertyPrice: 0,
      timeframe: 0,
      depositSavings: 0,
      monthlySaving: 0,
      goalsLocation: '',
      buyersQuantity: 0,
      financialSupport: 0,
      ownIncome: 0,
      secondAnnualIncome: 0,
      keyFactor: KEY_FACTOR_TYPES.BY_PRICE,
      goalsLocationParsed: {
        isEngland: false,
        isLondon: false,
        isNorthernIreland: false,
        isScotland: false,
        isWales: false
      }
    },
    'credit-score': {
      experian: '',
      tenant: false,
      regularRentPayments: false,
      rentPaidFromBankAccount: false
    },
    savings: {
      homeOrRentedOut: HOME_OR_RENTED_OUT.HOME
    },
    accounts: {
      items: {}
    },
    survey: {},
    consents: {},
    pension: {
      items: {}
    },
    beVM: {
      parsed: {}
    },
    scoreVM: {},
    positiveScoreVM: {},
    generated: {},
    property: {
      items: {},
      ltv: 0,
      rentalIncome: 0,
      marketRentalEstimate: 0,
      rentalOpportunity: 0,
      equityAvailable: 0,
      mortgageOutstanding: 0,
      equity: 0,
      portfolioImportStatus: ''
    },
    mortgage: {
      items: {}
    },
    brighterFuture: {
      items: {}
    },
    questionSection: {},
    user: {
      // used to set whether the application is embedded to other web app - can be reached by FE for display conditions and skip logic
      isEmbedded: undefined,
      segmentNumber: null,
      introducer: 'default',
      hasAccount: UserService.hasAccount(),
      hasUser: UserService.hasUser()
    }
  };

  const mergedState = ObjectShim.merge(defaultValues, state);

  return configIsNotYetReady ? defaultValues : ObjectShim.merge(mergedState, payload, mapAttributesAfterInitiate);
};

const initiateState =
  ({ payload, loading }) =>
  () => ({
    error: null,
    loading: loading,
    errorType: {},
    loadingType: {},
    ...mapAttributesValues({ payload })
  });

const init = initiateState({ payload: false, loading: !UserService.hasAccount() });
const reset = initiateState({ loading: false });

export const userAttributes = createReducer(init(), {
  [GET_USER_ATTRIBUTES_SUCCESS]: (state, action) => ({
    ...state,
    ...mapAttributesValues({ payload: action.payload, state }),
    error: null,
    loading: false
  }),
  [GET_USER_ATTRIBUTES_FAILURE]: state => ({ ...state, error: true }),
  [GET_SPECIFIED_USER_ATTRIBUTE_REQUEST]: (state, { params: type }) => ({
    ...state,
    loadingType: { ...state.loadingType, [type]: true }
  }),
  [GET_SPECIFIED_USER_ATTRIBUTE_SUCCESS]: (state, { params: type, payload }) => ({
    ...state,
    [type]: { ...state[type], ...payload },
    errorType: { ...state.errorType, [type]: null },
    loadingType: { ...state.loadingType, [type]: false }
  }),
  [GET_SPECIFIED_USER_ATTRIBUTE_FAILURE]: (state, { params: type, payload }) => ({
    ...state,
    errorType: { ...state.errorType, [type]: payload },
    loadingType: { ...state.loadingType, [type]: false }
  }),
  [SEND_USER_ATTRIBUTE_REQUEST]: (state, { params: { type } }) => ({
    ...state,
    loadingType: { ...state.loadingType, [type]: true }
  }),
  [SEND_USER_ATTRIBUTE_SUCCESS]: (state, { params: { type }, payload }) => ({
    ...state,
    [type]: { ...state[type], ...payload },
    errorType: { ...state.errorType, [type]: null },
    loadingType: { ...state.loadingType, [type]: false }
  }),
  [SEND_USER_ATTRIBUTE_FAILURE]: (state, { params: type, payload }) => ({
    ...state,
    errorType: { ...state.errorType, [type]: payload },
    loadingType: { ...state.loadingType, [type]: false }
  }),
  [SEND_ADDITIONAL_USER_ATTRIBUTES_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...ObjectShim.merge(state, payload)
  }),
  [SEND_ADDITIONAL_USER_ATTRIBUTES_FAILURE]: state => ({ ...state, error: true }),
  [LOG_OUT_SUCCESS]: () => {
    const resetState = reset();
    return {
      ...resetState,
      user: {
        ...resetState.user,
        hasAccount: true
      }
    };
  },
  [DELETE_ACCOUNT_SUCCESS]: reset,
  [DISPATCH_AFTER_USER_ATTRIBUTES]: (state, { params }) => ({ ...state, generated: { ...state.generated, ...params } }),
  [SEND_GROUP_USER_ATTRIBUTE_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...ObjectShim.merge(state, payload)
  }),
  [SEND_GROUP_USER_ATTRIBUTE_FAILURE]: state => ({ ...state, error: true }),
  [CREATE_SHADOW_USER_SUCCESS]: (
    state,
    { payload: { segmentNumber, introducerCode: introducer = 'default', introducer: introducerData } }
  ) => ({
    ...state,
    user: { ...state.user, segmentNumber, introducer, brokerName: introducerData?.brokerName }
  }),
  [GET_USER_SUCCESS]: (
    state,
    { payload: { segmentNumber, introducerCode: introducer = 'default', introducer: introducerData } }
  ) => ({
    ...state,
    user: { ...state.user, segmentNumber, introducer, brokerName: introducerData?.brokerName }
  }),
  [LOGIN_SUCCESS]: state => ({
    ...state,
    user: { ...state.user, hasAccount: false, hasUser: true }
  }),
  [CONFIRM_SIGN_UP_SUCCESS]: state => ({
    ...state,
    user: { ...state.user, hasAccount: true, hasUser: false }
  })
});
