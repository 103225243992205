import { compose } from 'redux';
import { isHtmlElement } from '../layout/element';
import { changeEmbeddedMedia } from './components/embed';
import { changeImages } from './components/image';
import { changeLinks } from './components/link';
import { changeButtons } from './components/button';
import { changeCoachies } from './components/coachie';
import { changePoints } from './components/points';
import { changeCallout } from './components/callout';

export const modifyHtml =
  (history, state) =>
  (element, { embeds, images, links, buttons, coachies, points, callout }) => {
    if (isHtmlElement(element)) {
      const pipe = [
        ...(embeds ? [changeEmbeddedMedia] : []),
        ...(images ? [changeImages] : []),
        ...(links ? [changeLinks(history)] : []),
        ...(buttons ? [changeButtons(history)] : []),
        ...(coachies ? [changeCoachies(history)] : []),
        ...(points ? [changePoints(state)] : []),
        ...(callout ? [changeCallout] : [])
      ];

      compose(...pipe)(element);
    }
  };
