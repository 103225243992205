import React from 'react';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { InputGroup } from '../../../components/inputs/input-group';
import { InputToggleSwitch as ToggleSwitch } from '../../../components/inputs/toggle-switch';
import { sendConsents } from '../../../store/reducers/user-attributes/actions';
import { withLoadingHandlers } from '../../../components/with-loader';
import { withContainer } from '../../../components/inputs/input-field';
import { sortByWeight } from '../../../helpers/array';

const ConsentListsComponent = React.memo(
  ({ value, label, consents, handleChange, loading, onChange, attributesConsent }) => (
    <div>
      {label && <p className='label-s content strong'>{label}</p>}
      <InputGroup>
        {consents.map(({ id, title, body, type, locked }) => (
          <ToggleSwitch
            key={id}
            name={id}
            label={title}
            explainer={body}
            locked={locked}
            value={onChange ? value[type] : attributesConsent[type]}
            onChange={handleChange(type)}
            disabled={locked || loading}
          />
        ))}
      </InputGroup>
    </div>
  )
);

export const ConsentLists = compose(
  connect(
    state => ({
      consents: state.consentList.list,
      attributesConsent: state.userAttributes.consents
    }),
    { sendConsents }
  ),
  withPropsOnChange(['consents'], ({ consents }) => ({
    consents: sortByWeight(consents)
  })),
  withLoadingHandlers(),
  withHandlers({
    handleChange:
      ({ sendConsents, turnOnLoading, turnOffLoading, onChange, value }) =>
      type =>
      async ({ target: { checked } }) => {
        if (!onChange) {
          turnOnLoading();
          await sendConsents({ [type]: checked });
          turnOffLoading();
          return;
        }

        onChange({ ...value, [type]: checked });
      }
  }),
  withContainer
)(ConsentListsComponent);
