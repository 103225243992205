import React from 'react';
import styles from '../../../../styles/components/page-header/secondary-header.module.scss';
import { getDateFromNow, getMinutesRead } from '../../../../helpers/articles/article';
import { Column, Grid } from '../../grid';
import { BlogBackButton, Category, Heading, SubHeading } from '../components';
import { SubBlock } from '../components/sub-block';
import { HeaderContent } from '../components/header-content-container';
import { PageActionsSection } from '../../../page-actions-section';

const Meta = React.memo(({ articleBody, modifiedDate }) => (
  <ul className={styles.meta}>
    <li className={styles['meta-item']}>{getMinutesRead(articleBody)}</li>
    <li className={styles['meta-item']}>Updated {getDateFromNow(modifiedDate)}</li>
  </ul>
));

export const SecondaryHeader = React.memo(
  ({
    type,
    heading,
    subheading,
    image,
    alt,
    category,
    modifiedDate,
    articleBody,
    showBackLink,
    goToBlog,
    subBlockName,
    headerButtons = []
  }) => (
    <Grid className={styles.container}>
      <Column edge>
        <div className={styles.wrapper}>
          <HeaderContent className={styles.content} contentAlign='right'>
            {category && <Category className={styles['category-body']}>{category}</Category>}
            {type === 'blog' && showBackLink && <BlogBackButton onClick={goToBlog} />}
            {heading && <Heading>{heading}</Heading>}
            {subheading && <SubHeading>{subheading}</SubHeading>}
            {(modifiedDate || articleBody) && <Meta modifiedDate={modifiedDate} articleBody={articleBody} />}
            {headerButtons.length ? <PageActionsSection actionsList={headerButtons} /> : null}
          </HeaderContent>
          <SubBlock subBlockName={subBlockName} image={image} alt={alt} category={category} />
        </div>
      </Column>
    </Grid>
  )
);
