import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useClickOutside } from '@packages/hooks/core/use-click-outside';
import { useMergeRefs } from '@packages/hooks/core/use-merge-refs';
import styles from '../../styles/components/modal.module.scss';
import { Spinner } from '../with-loader';
import { Dimmer } from '../layout/dimmer';
import { FocusTrap } from '../layout/focus-trap';
import { SafeArea } from '../layout/safe-area';
import { ScrollContainer } from '../layout/scroll-container';
import { ModalHeader } from './header';
import { ModalFooter } from './footer';

export const ModalPortal = ({ children }) => ReactDOM.createPortal(children, document.getElementById('modal'));

//TODO: create useModal hook to simplify Modal creation
export const Modal = React.memo(
  ({ show = false, children, onClose, loading, leftImage, label, title, subTitle, header, footer, className }) => {
    const dialog = useRef(null);

    const [maxHeight, setHeight] = useState();

    const [anchor, handleClickOutside] = useClickOutside(onClose);

    useEffect(() => {
      const { current: dialogRef } = dialog;

      if (show) {
        setHeight(loading ? dialogRef?.clientHeight : void 0);
      }
    }, [show, loading]);

    return (
      <ModalPortal>
        <Dimmer
          on={show}
          className={classnames(styles.container, className)}
          display='flex'
          onClick={handleClickOutside}
          onEscClick={onClose}
        >
          <CSSTransition in={show} timeout={200} classNames={{ ...styles }} exit={false} appear>
            <div
              ref={useMergeRefs(dialog, anchor)}
              role='dialog'
              aria-label={label || title || 'dialog'}
              className={styles.dialog}
              style={{ maxHeight }}
            >
              <ScrollContainer disabled={show}>
                <FocusTrap disabled={!show}>
                  {show && (
                    <SafeArea edges={['left', 'right', 'bottom']} fixed timeout={250}>
                      <ModalHeader
                        leftImage={leftImage}
                        title={title}
                        subTitle={subTitle}
                        html={header?.html}
                        onClose={onClose}
                      >
                        {header}
                      </ModalHeader>
                      <section className={styles.body}>
                        {loading ? <Spinner className={styles.loader} /> : children}
                      </section>
                      {footer && <ModalFooter html={footer?.html}>{footer}</ModalFooter>}
                    </SafeArea>
                  )}
                </FocusTrap>
              </ScrollContainer>
            </div>
          </CSSTransition>
        </Dimmer>
      </ModalPortal>
    );
  }
);
