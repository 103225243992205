export class SelectService {
  static getSelects() {
    const selects = sessionStorage.getItem('selects');

    if (selects) {
      return JSON.parse(selects);
    } else {
      const selects = {};

      sessionStorage.setItem('selects', JSON.stringify(selects));

      return selects;
    }
  }

  static deleteSelects() {
    const selects = sessionStorage.getItem('selects');

    if (selects) {
      sessionStorage.removeItem('selects');
    }

    return Boolean(selects);
  }

  static setSelects({ name, value }) {
    const selects = {
      ...this.getSelects(),
      [name]: value
    };
    sessionStorage.setItem('selects', JSON.stringify(selects));

    return value;
  }
}
