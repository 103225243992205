import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { propertyAddress } from '../../../api/property-address';
import { BugsnagService } from '../../../services/bugsnag';
import { deleteCookie } from '../../../helpers/cookie';
import { transformBeforeSend } from '../../../helpers/user-attributes/transform-before-send';
import { createAction } from '../../utils';
import { setRefreshPageBackLink } from '../digital-coach';
import { subscribeTooltip } from '../tooltips';

export const GET_USER_ATTRIBUTES_SUCCESS = 'GET_USER_ATTRIBUTES_SUCCESS';
export const GET_USER_ATTRIBUTES_FAILURE = 'GET_USER_ATTRIBUTES_FAILURE';

export const GET_SPECIFIED_USER_ATTRIBUTE_REQUEST = 'GET_SPECIFIED_USER_ATTRIBUTE_REQUEST';
export const GET_SPECIFIED_USER_ATTRIBUTE_SUCCESS = 'GET_SPECIFIED_USER_ATTRIBUTE_SUCCESS';
export const GET_SPECIFIED_USER_ATTRIBUTE_FAILURE = 'GET_SPECIFIED_USER_ATTRIBUTE_FAILURE';

export const SEND_USER_ATTRIBUTE_REQUEST = 'SEND_USER_ATTRIBUTE_REQUEST';
export const SEND_USER_ATTRIBUTE_SUCCESS = 'SEND_USER_ATTRIBUTE_SUCCESS';
export const SEND_USER_ATTRIBUTE_FAILURE = 'SEND_USER_ATTRIBUTE_FAILURE';

export const SEND_ADDITIONAL_USER_ATTRIBUTES_REQUEST = 'SEND_ADDITIONAL_USER_ATTRIBUTES_REQUEST';
export const SEND_ADDITIONAL_USER_ATTRIBUTES_SUCCESS = 'SEND_ADDITIONAL_USER_ATTRIBUTES_SUCCESS';
export const SEND_ADDITIONAL_USER_ATTRIBUTES_FAILURE = 'SEND_ADDITIONAL_USER_ATTRIBUTES_FAILURE';

export const SEND_GROUP_USER_ATTRIBUTE_REQUEST = 'SEND_GROUP_USER_ATTRIBUTE_REQUEST';
export const SEND_GROUP_USER_ATTRIBUTE_SUCCESS = 'SEND_GROUP_USER_ATTRIBUTE_SUCCESS';
export const SEND_GROUP_USER_ATTRIBUTE_FAILURE = 'SEND_GROUP_USER_ATTRIBUTE_FAILURE';

export const DISPATCH_AFTER_USER_ATTRIBUTES = 'DISPATCH_AFTER_USER_ATTRIBUTES';

export const getUserAttributes = () =>
  createAction(
    {
      success: GET_USER_ATTRIBUTES_SUCCESS,
      failure: GET_USER_ATTRIBUTES_FAILURE
    },
    api => api.userAttributes.getUserAttributes
  )();

export const getSpecifiedUserAttribute = type => () =>
  createAction(
    {
      request: GET_SPECIFIED_USER_ATTRIBUTE_REQUEST,
      success: GET_SPECIFIED_USER_ATTRIBUTE_SUCCESS,
      failure: GET_SPECIFIED_USER_ATTRIBUTE_FAILURE
    },
    api => api.userAttributes.getUserAttributes
  )(type);

export const sendUserAttribute = type => (params, cb) => async (dispatch, getState) => {
  const state = getState();
  const userAttributes = state.userAttributes;

  const data = transformBeforeSend({ type, params, prevState: userAttributes });

  const response = await dispatch(
    createAction(
      {
        request: SEND_USER_ATTRIBUTE_REQUEST,
        success: SEND_USER_ATTRIBUTE_SUCCESS,
        failure: SEND_USER_ATTRIBUTE_FAILURE
      },
      api => api.userAttributes.sendUserAttribute
    )({ type, params: data })
  );

  if (cb) {
    cb();
  }

  return response;
};

export const getGoal = getSpecifiedUserAttribute('goal'); // TODO: does not use anywhere?
export const getAccounts = getSpecifiedUserAttribute('accounts'); // TODO: does not use anywhere?

export const sendGoal = sendUserAttribute('goal');
export const sendLastQuestion = sendUserAttribute('lastQuestion');
export const sendQuestionSectionInfo = sendUserAttribute('questionSection');
export const sendSavings = sendUserAttribute('savings'); // TODO: does not use anywhere?
export const sendCreditScore = sendUserAttribute('credit-score');
export const sendAccounts = sendUserAttribute('accounts');
export const sendEligibility = sendUserAttribute('eligibility');
export const sendPension = sendUserAttribute('pension');
export const sendAltAccounts = sendUserAttribute('altAccounts');
export const sendApplicationData = sendUserAttribute('application');

export const sendConsents = params => (dispatch, getState) => {
  const {
    consentList: { normalizedList },
    userAttributes: { consents }
  } = getState();

  const action = sendUserAttribute('consents')(params, () => {
    Object.keys(params).forEach(type => {
      const relatedCookies = normalizedList[type]?.relatedCookies;

      const consentWasGranted = consents[type];
      const consentHasBeenRejected = params[type] === false;

      if (params.tracking) {
        BugsnagService.initialize();
      }

      if (consentWasGranted && consentHasBeenRejected && Array.isArray(relatedCookies)) {
        // Here we can specify domains which cookies will be cleared
        // on opt-outing apart from default domain
        const domains = [`.${window.location.hostname}`]; // to be expanded;

        relatedCookies.forEach(cookie => {
          deleteCookie(cookie, { byOrigin: true, domains });
        });
      }
    });
  });

  return dispatch(action);
};

export const sendPropertyGoal = goal => async dispatch => {
  dispatch(setRefreshPageBackLink('/dashboard'));
  dispatch(subscribeTooltip('road-user', { once: true }));

  return dispatch(sendGoal(goal));
};

export const getPropertyByUprnCode = params => async dispatch => {
  const type = 'property';

  dispatch({ type: GET_SPECIFIED_USER_ATTRIBUTE_REQUEST, params: type });

  try {
    const response = await propertyAddress.getPropertyByUprnCode(params);

    if (response.ok) {
      const items = await response.json();

      return dispatch({ type: GET_SPECIFIED_USER_ATTRIBUTE_SUCCESS, params: type, payload: { items } });
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    dispatch({ type: GET_SPECIFIED_USER_ATTRIBUTE_FAILURE, params: type, payload: error });

    throw new Error(error);
  }
};

export const deletePension = companyName => (dispatch, getState) => {
  const items = getState().userAttributes.pension.items;

  if (companyName in items) {
    dispatch(sendPension({ items: ObjectShim.omit(items, [companyName]) }));
  }
};

export const sendAdditionalUserAttributes = (params, mergeObjects) =>
  createAction(
    {
      request: SEND_ADDITIONAL_USER_ATTRIBUTES_REQUEST,
      success: SEND_ADDITIONAL_USER_ATTRIBUTES_SUCCESS,
      failure: SEND_ADDITIONAL_USER_ATTRIBUTES_FAILURE
    },
    api => api.userAttributes.sendAdditionalUserAttributes
  )({ params, mergeObjects });

export const sendUserGroupAttributes = (params, mergeObjects) =>
  createAction(
    {
      request: SEND_GROUP_USER_ATTRIBUTE_REQUEST,
      success: SEND_GROUP_USER_ATTRIBUTE_SUCCESS,
      failure: SEND_GROUP_USER_ATTRIBUTE_FAILURE
    },
    api => api.userAttributes.sendUserGroupAttributes
  )({ params, mergeObjects });

export const setGeneratedData = params => ({ type: DISPATCH_AFTER_USER_ATTRIBUTES, params });
