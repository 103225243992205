import { articlesRoutes } from '@apps/firsthomecoach/src/routes/articles';
import { contentFeedRoutes } from '@apps/firsthomecoach/src/routes/content-feed';
import { questions } from '@apps/firsthomecoach/src/routes/questions';
import { homePageRoutes } from '@apps/firsthomecoach/src/routes/home-pages';
import { accountsRoutes } from '@apps/firsthomecoach/src/routes/account';
import { storiesRoutes } from '@apps/firsthomecoach/src/routes/stories';
import { tourPageRoutes } from '@apps/firsthomecoach/src/routes/tour-page';
import { statusPageRoutes } from '@apps/firsthomecoach/src/routes/status-page';
import { rentReportingRoutes } from '@apps/firsthomecoach/src/routes/rent-reporting';
import { legalPageRoutes } from '@apps/firsthomecoach/src/routes/legal-page';
import { blogRoutes } from '@apps/firsthomecoach/src/routes/blog';
import { videoRoutes } from '@apps/firsthomecoach/src/routes/videos';

import { dashboardRoutes } from './dashboard';
import { customPageRoutes } from './custom-page';

export const routes = [
  ...dashboardRoutes,
  ...customPageRoutes,
  ...articlesRoutes,
  ...homePageRoutes,
  ...accountsRoutes,
  ...blogRoutes,
  ...tourPageRoutes,
  ...questions,
  ...rentReportingRoutes,
  ...contentFeedRoutes,
  ...storiesRoutes,
  ...legalPageRoutes,
  ...statusPageRoutes,
  ...videoRoutes
];
