import Overlay from '@apps/firsthomecoach/src/components/video-overlay';
import React from 'react';
import { withApp } from '@apps/firsthomecoach/src/components/layout/with-app';
import { SmartBanner } from '@apps/firsthomecoach/src/components/smartbanner';
import { ClientVerificationModal } from '@apps/firsthomecoach/src/components/layout/popups/client-verification-popup';
import { RequiredActivityController } from '@apps/firsthomecoach/src/components/layout/required-activity-controller';

import { Application } from './components/layout/application';
import { Content } from './components/layout/content';

const Component = () => {
  return (
    <>
      <SmartBanner />
      <Application>
        <ClientVerificationModal>
          <RequiredActivityController />
          <Overlay />
          <Content />
        </ClientVerificationModal>
      </Application>
    </>
  );
};

export const App = withApp(Component);
