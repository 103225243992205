import { coreReducers } from '@apps/firsthomecoach/src/store/reducers';
import { depositBuilder } from '@apps/firsthomecoach/src/store/reducers/deposit-builder';
import { lottie } from '@apps/firsthomecoach/src/store/reducers/lottie';
import { wheels } from '@apps/firsthomecoach/src/store/reducers/wheels';
import { combineReducers } from 'redux';

const reducers = combineReducers({
  ...coreReducers,
  lottie,
  wheels,
  depositBuilder
});

export { reducers };
