import React from 'react';
import PropTypes from 'prop-types';
import { CategoryFilterComponent } from '@apps/firsthomecoach/src/components/layout/category-filter';
import { Header } from '@apps/firsthomecoach/src/components/layout/page-header';
import { PageAction } from '@apps/firsthomecoach/src/components/page-actions-section/components/page-action';
import { Column, Grid } from '@packages/ui-kit/core/grid';
import { Footer } from '../../components/layout/page-footer';
import StatusToggler from '../../components/status-filter';
import styles from '../../styles/templates/content-feed-template.module.scss';
import { useRepeaterCardIntersection } from '../../helpers/repeaters/use-repeater-card-intersection';
import List from './list';

const ContentFeedTemplate = React.memo(props => {
  const {
    pageHeader,
    pageAction,
    emptyCategoryText,
    emptyEmptyStateText,
    feedItems,
    feedLoading,
    selectedCategory,
    categoriesOptions,
    isActiveStatus,
    handleChangeCategory,
    handleToggleStatus,
    pageType,
    repeaterKey,
    filterOptions,
    saveIconOptions,
    isDisplayItemSubTitle,
    isDisplayItemExcerpt,
    isDisplayCardSaveButton
  } = props;

  const { onCardInView } = useRepeaterCardIntersection({
    pageType
  });

  // Such type of condition check needed for existing products, where these options are not set,
  // so when option is undefined - it will be shown
  const showFilter = filterOptions?.show !== false;
  const showSavedIcon = saveIconOptions?.show !== false;

  return (
    <>
      {pageHeader?.isVisible && (
        <Header
          className={styles.header}
          heading={pageHeader?.heading}
          subheading={pageHeader?.subheading}
          category={pageHeader?.category}
          image={pageHeader?.image}
          type={pageHeader?.type}
          backgroundPosition={pageHeader?.backgroundPosition}
        />
      )}
      <Grid className={styles.content}>
        <Column>
          {pageAction?.isVisible && (
            <PageAction iconName='nav-back' to={pageAction?.link}>
              {pageAction?.text}
            </PageAction>
          )}
          {showFilter && (
            <CategoryFilterComponent
              values={categoriesOptions}
              select={selectedCategory}
              handleSelect={handleChangeCategory}
              filterTitle={filterOptions?.filterTitle}
            />
          )}
          {showSavedIcon && (
            <StatusToggler toggle={handleToggleStatus} isActive={isActiveStatus} label={saveIconOptions?.label} />
          )}
        </Column>
        <Column>
          <List
            emptyCategoryText={emptyCategoryText}
            emptyEmptyStateText={emptyEmptyStateText}
            items={feedItems}
            loading={feedLoading}
            isSavedStatus={isActiveStatus}
            repeaterKey={repeaterKey}
            isDisplayItemSubTitle={isDisplayItemSubTitle}
            isDisplayItemExcerpt={isDisplayItemExcerpt}
            isDisplayCardSaveButton={isDisplayCardSaveButton}
            onCardInView={onCardInView}
          />
        </Column>
      </Grid>
      <Footer pageType='feed' />
    </>
  );
});

ContentFeedTemplate.propTypes = {
  pageHeader: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    category: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    backgroundPosition: PropTypes.string,
    isVisible: PropTypes.bool
  }),
  pageAction: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
    iconName: PropTypes.string,
    isVisible: PropTypes.bool
  }),
  emptyCategoryText: PropTypes.string.isRequired,
  emptyEmptyStateText: PropTypes.string.isRequired,
  feedLoading: PropTypes.bool.isRequired,
  feedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['article', 'video', 'story', 'blog', 'activity', 'project']).isRequired,
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  categoriesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      }),
      disabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  isActiveStatus: PropTypes.bool.isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
  handleToggleStatus: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  repeaterKey: PropTypes.string.isRequired,
  filterOptions: PropTypes.shape({
    show: PropTypes.bool,
    filterTitle: PropTypes.string
  }),
  saveIconOptions: PropTypes.shape({
    show: PropTypes.bool,
    label: PropTypes.string
  }),
  isDisplayItemSubTitle: PropTypes.bool,
  isDisplayItemExcerpt: PropTypes.bool,
  isDisplayCardSaveButton: PropTypes.bool
};

ContentFeedTemplate.displayName = 'ContentFeedTemplate';

export default ContentFeedTemplate;
