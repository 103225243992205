import { matchPath } from 'react-router-dom';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import * as NAVIGATION from '../navigation';

const computeShouldRenderSidebar = () => {
  const cache = {};

  return (pathname, tabs) => {
    if (cache[pathname] !== void 0) return cache[pathname];

    return tabs.some(({ link, sidebarRequired }) => {
      const isMatched = !!matchPath(pathname, link) && sidebarRequired;

      cache[pathname] = isMatched;

      return isMatched;
    });
  };
};

export const shouldRenderSidebar = computeShouldRenderSidebar();

const THEME_MAP = [
  [NAVIGATION.START, '/(checklist|market-place)/:module/start'],
  [NAVIGATION.RESULT, '/(checklist|market-place)/:module/(result|overview)'],
  [NAVIGATION.CHECKPOINT, '/(checklist|market-place|account)/:module/(done|completed)'],
  [NAVIGATION.QUESTION, '/(checklist|market-place)/:module'],
  [NAVIGATION.ARTICLE, '/(articles|blog)/:slug'],
  [NAVIGATION.TOUR, '/tour'],
  [NAVIGATION.LANDING, '/lp'],
  [NAVIGATION.ACCOUNT_COMPLETE, '/account/:flow/complete'],
  [NAVIGATION.ACCOUNT_SIGNIN, '/account/login'],
  [NAVIGATION.ACCOUNT_COMPLETE, '/account/sign-up/account-ready'],
  [NAVIGATION.ACCOUNT_SIGNUP, '/account/sign-up'],
  [NAVIGATION.ACCOUNT_RESET_PASSWORD, '/account/reset-password'],
  [NAVIGATION.ACCOUNT_COMPLETE, '/account/logout'],
  [NAVIGATION.ACCOUNT_HOME_OPTION, '/account/:opt'],
  [NAVIGATION.ACCOUNT_HOME, '/account'],
  [NAVIGATION.CHECKLIST, '/checklist'],
  [NAVIGATION.ARTICLES, '/articles'],
  [NAVIGATION.PROJECT, '/projects'],
  [NAVIGATION.BLOG, '/blog'],
  [NAVIGATION.DASHBOARD, '/dashboard'],
  [NAVIGATION.COINS, '/coins'],
  [NAVIGATION.VIDEO, '/videos/:slug']
];

const computeTheme = () => {
  let cache = {};

  // Create a cache purge flag.
  cache._PURGED = false;

  return (pathname, themedPages) => {
    // When themedPages are resolved we should purge cached values. But only once.
    if (themedPages && !cache._PURGED) {
      cache = {};
      // Purge cache
      cache._PURGED = true;
    }

    // Take a theme name from a cache object.
    if (cache[pathname] !== void 0) {
      return cache[pathname];
    }

    const theme = [
      ...(themedPages ? themedPages.tourPages : []),
      ...(themedPages ? themedPages.pages : []),
      ...THEME_MAP
    ].find(([_, predicate]) => matchPath(pathname, predicate));

    // if no theme specify for a pathname. Save null to the cache.
    const themeName = theme ? theme[0] : null;

    cache[pathname] = themeName;

    return themeName;
  };
};

export const getThemeByPathname = computeTheme();

// CSS env() value arn't available during initializing stage.
// So they should be computed when app is rendered.
// To prevent recomputation on each re-rendering result should be memoized.
const computeWindowSafeAreaInset = () => {
  let cache = null;

  return () => {
    if (cache) return cache;

    const attrs = ['top', 'right', 'bottom', 'left'];

    const computed = attrs.reduce((acc, attr) => {
      const value = getComputedStyle(document.documentElement).getPropertyValue(`--sa${attr[0]}`);

      return {
        ...acc,
        [attr]: value ? parseInt(value) : 0
      };
    }, {});

    cache = computed;

    return computed;
  };
};

export const getWindowSafeAreaInset = computeWindowSafeAreaInset();

// During initial stage body isn't have enough content to have a scrollbar
// Some dummy node with hardcoded scrollbar should be rendered to measure scrollbar
// Computation should be stored to a constant and exported
export const SCROLL_BAR_WIDTH = (() => {
  const dummy = document.createElement('div');

  ObjectShim.entries({ width: '300px', height: '300px', overflow: 'scroll' }).forEach(
    ([prop, value]) => (dummy.style[prop] = value)
  );

  document.body.appendChild(dummy);

  const width = dummy.offsetWidth - dummy.clientWidth;

  document.body.removeChild(dummy);

  return width;
})();

export const getS3EntityByAffiliate = ({ name, path, extension }) => {
  // eslint-disable-next-line no-undef
  return `/config/${path}/${name}.${extension}`;
};

export const getIconUrlByName = name => {
  return getS3EntityByAffiliate({ name, path: 'icons', extension: 'svg' });
};
export const getImageUrlByName = (name, extension = 'jpg') => {
  return getS3EntityByAffiliate({ name, path: 'images', extension });
};
