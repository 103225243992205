import { createReducer } from '../utils';
import { statusData } from '../../api/status-data';
import { DELETE_ACCOUNT_SUCCESS } from './account/action-types';

const GET_NEXT_ACTIVITY_REQUEST = 'GET_NEXT_ACTIVITY_REQUEST';
const GET_NEXT_ACTIVITY_SUCCESS = 'GET_NEXT_ACTIVITY_SUCCESS';
const GET_NEXT_ACTIVITY_FAILURE = 'GET_NEXT_ACTIVITY_FAILURE';
const GET_SPECIFIC_ACTIVITY_REQUEST = 'GET_SPECIFIC_ACTIVITY_REQUEST';
const GET_SPECIFIC_ACTIVITY_SUCCESS = 'GET_SPECIFIC_ACTIVITY_SUCCESS';
const GET_SPECIFIC_ACTIVITY_FAILURE = 'GET_SPECIFIC_ACTIVITY_FAILURE';
const SET_REFRESH_PAGE_BACK_LINK = 'SET_REFRESH_PAGE_BACK_LINK';
const SET_ACTIVITY_FORCED_LOADING = 'SET_ACTIVITY_FORCED_LOADING';

const initialState = {
  nextActivity: {},
  specificActivity: {},
  forcedLoading: false,
  loading: true,
  error: false,
  to: '/'
};

export const getNextActivity = () => async dispatch => {
  try {
    dispatch({ type: GET_NEXT_ACTIVITY_REQUEST });

    const response = await statusData.getNextActivity();

    const nextActivity = await response.json();

    dispatch({ type: GET_NEXT_ACTIVITY_SUCCESS, payload: nextActivity });
  } catch (e) {
    dispatch({ type: GET_NEXT_ACTIVITY_FAILURE });
  }
};

export const getSpecificActivity = activity => async dispatch => {
  try {
    dispatch({ type: GET_SPECIFIC_ACTIVITY_REQUEST });

    const response = await statusData.getSpecificActivity(activity);

    const specificActivity = await response.json();

    dispatch({ type: GET_SPECIFIC_ACTIVITY_SUCCESS, payload: specificActivity });
  } catch (e) {
    dispatch({ type: GET_SPECIFIC_ACTIVITY_FAILURE });
  }
};

export const enableCoachLoader = () => ({ type: GET_NEXT_ACTIVITY_REQUEST });

export const toggleCoachForcedLoading = forcedLoading => ({ type: SET_ACTIVITY_FORCED_LOADING, forcedLoading });

export const setRefreshPageBackLink = to => ({ type: SET_REFRESH_PAGE_BACK_LINK, to });

export const digitalCoach = createReducer(initialState, {
  [GET_NEXT_ACTIVITY_REQUEST]: state => ({ ...state, loading: true }),
  [GET_NEXT_ACTIVITY_SUCCESS]: (state, action) => ({ ...state, nextActivity: action.payload, loading: false }),
  [GET_NEXT_ACTIVITY_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_SPECIFIC_ACTIVITY_REQUEST]: state => ({ ...state, loading: true }),
  [GET_SPECIFIC_ACTIVITY_SUCCESS]: (state, action) => ({ ...state, specificActivity: action.payload, loading: false }),
  [GET_SPECIFIC_ACTIVITY_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [SET_REFRESH_PAGE_BACK_LINK]: (state, { to }) => ({ ...state, to }),
  [SET_ACTIVITY_FORCED_LOADING]: (state, { forcedLoading }) => ({ ...state, forcedLoading }),
  [DELETE_ACCOUNT_SUCCESS]: () => ({ ...initialState })
});
